'use client' // Error components must be Client Components

import { useEffect } from 'react'

export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)

    // Add global unhandled promise rejection handler
    const handleUnhandledRejection = (event) => {
      console.warn('Unhandled promise rejection:', event.reason);
      // Optionally, you can add more detailed logging or error reporting here
      // For example, you might want to check if it's a Twilsock error:
      if (event.reason && event.reason.name === 'TwilsockError') {
        console.warn('Twilsock error detected:', event.reason.message);
        // You could potentially trigger some recovery action here
      }
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, [error])

  return (
    <div>
      <h2>Etwas ist schiefgelaufen</h2>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Bitte versuchen Sie es erneut
      </button>
    </div>
  )
}